export const formatDate = (date: Date) => {
  let formatted = "";
  let items = date.toDateString().split(" ");

  for (let i = 1; i < items.length; i++) {
    if (i === 2) formatted = formatted.concat(items[i] + ", ");

    if (i !== items.length - 1 && i !== 2)
      formatted = formatted.concat(items[i] + " ");

    if (i === items.length - 1) formatted = formatted.concat(items[i]);
  }
  return formatted;
};
