import { Link, navigate } from "gatsby";
import React from "react";
import { AiFillTag } from "react-icons/ai";

interface Props {
  text: string;
}

const Tag = ({ text }: Props) => {
  const linkLookup: any = {
    NLP: "/natural-language-processing",
    Data: "/data",
    Industry: "/industry",
    IoT: "/internet-of-things",
    Cloud: "/cloud",
    Developer: "/developer",
    "Company News": "/company-news",
  };

  return (
    <Link
      to={linkLookup[text]}
      state={{ query: text, filterType: "tag" }}
      className="tag-label"
    >
      <AiFillTag /> {text}
    </Link>
  );
};

export default Tag;
